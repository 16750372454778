import { createReducer, on } from '@ngrx/store';
import {
  Microcourse,
  MicrocourseResponse,
} from 'src/app/models/microcourse.model';
import * as MicrocourseActions from './microcourse.actions';
import { MicrocourseState } from './microcourse.state';

const initialState: MicrocourseState = {
  userManagementMicrocourses: [],
  isGettingUserManagementMicrocourse: false,
  getUserManagementMicrocourseErrorMessage: '',

  microcourseResponse: <MicrocourseResponse>{},
  isLoadingResponse: false,
  loadResponseErrorMessage: '',

  microcourse: <Microcourse>{},
  isGetting: false,
  getErrorMessage: '',

  isSearching: false,
  searchMicrocourseResult: [],
  searchErrorMessage: '',

  isGettingEnrolled: false,
  enrolledMicrocourses: [],
  getEnrolledErrorMessage: '',

  isGettingInfo: false,
  microcourseInfo: <Microcourse>{},
  getInfoErrorMessage: '',

  isGettingCourseOfSkillsetInfo: false,
  courseOfSkillsetInfoList: [],
  getCourseOfSkillsetInfoErrorMessage: '',

  embedContent: '',
  isGettingEmbedContent: false,
  getEmbedContentErrorMessage: '',
};

export const microcourseReducer = createReducer(
  initialState,

  on(MicrocourseActions.clearStates, (state, action) => {
    console.log(action.type);
    return <MicrocourseState>{
      ...state,
      userManagementMicrocourses: [],
      isGettingUserManagementMicrocourse: false,
      getUserManagementMicrocourseErrorMessage: '',

      microcourseResponse: <MicrocourseResponse>{},
      isLoadingResponse: false,
      loadResponseErrorMessage: '',

      microcourse: <Microcourse>{},
      isGetting: false,
      getErrorMessage: '',

      isCreating: false,
      isCreateSuccess: false,
      createErrorMessage: '',

      isUpdating: false,
      isUpdateSuccess: false,
      updateErrorMessage: '',

      isDeleting: false,
      isDeleteSuccess: false,
      deleteErrorMessage: '',

      isPublishing: false,
      isPublishSuccess: false,
      publishErrorMessage: '',

      isSearching: false,
      searchMicrocourseResult: [],
      searchErrorMessage: '',

      isGettingEnrolled: false,
      enrolledMicrocourses: [],
      getEnrolledErrorMessage: '',

      isGettingInfo: false,
      microcourseInfo: <Microcourse>{},
      getInfoErrorMessage: '',

      embedContent: '',
      isGettingEmbedContent: false,
      getEmbedContentErrorMessage: '',
    };
  }),
  on(MicrocourseActions.clearMessages, (state, action) => {
    console.log(action.type);
    return <MicrocourseState>{
      ...state,
      isGettingUserManagementMicrocourse: false,
      getUserManagementMicrocourseErrorMessage: '',

      isLoadingResponse: false,
      loadResponseErrorMessage: '',

      isGetting: false,
      getErrorMessage: '',

      isCreating: false,
      isCreateSuccess: false,
      createErrorMessage: '',

      isUpdating: false,
      isUpdateSuccess: false,
      updateErrorMessage: '',

      isDeleting: false,
      isDeleteSuccess: false,
      deleteErrorMessage: '',

      isPublishing: false,
      isPublishSuccess: false,
      publishErrorMessage: '',

      isSearching: false,
      searchErrorMessage: '',

      isGettingEnrolled: false,
      getEnrolledErrorMessage: '',

      isGettingInfo: false,
      getInfoErrorMessage: '',

      isGettingEmbedContent: false,
      getEmbedContentErrorMessage: '',
    };
  }),

  //dynamic for all
  on(MicrocourseActions.getResponse, (state, action) => {
    console.log(action.type);
    return <MicrocourseState>{
      ...state,
      isLoadingResponse: true,
      microcourseResponse: <MicrocourseResponse>{},
      loadResponseErrorMessage: '',
    };
  }),
  on(MicrocourseActions.getResponseSuccess, (state, { type, microcourses }) => {
    console.log(type);
    return <MicrocourseState>{
      ...state,
      isLoadingResponse: false,
      microcourseResponse: microcourses,
    };
  }),
  on(MicrocourseActions.getResponseFailure, (state, action) => {
    console.log(action.type);
    return <MicrocourseState>{
      ...state,
      isLoadingResponse: false,
      loadResponseErrorMessage: action.errorMessage,
    };
  }),

  on(MicrocourseActions.getById, (state, action) => {
    console.log(action.type);
    return <MicrocourseState>{
      ...state,
      isGetting: true,
      getErrorMessage: '',
      microcourse: <Microcourse>{},
    };
  }),
  on(MicrocourseActions.getByIdSuccess, (state, action) => {
    console.log(action.type);
    return <MicrocourseState>{
      ...state,
      isGetting: false,
      microcourse: action.microcourse,
    };
  }),
  on(MicrocourseActions.getByIdFailure, (state, action) => {
    console.log(action.type);
    return <MicrocourseState>{
      ...state,
      isGetting: false,
      getErrorMessage: action.errorMessage,
    };
  }),

  on(MicrocourseActions.getInfo, (state, action) => {
    console.log(action.type);
    return <MicrocourseState>{
      ...state,
      isGettingInfo: true,
      getInfoErrorMessage: '',
      microcourseInfo: <Microcourse>{},
    };
  }),
  on(MicrocourseActions.getInfoSuccess, (state, action) => {
    console.log(action.type);
    return <MicrocourseState>{
      ...state,
      isGettingInfo: false,
      microcourseInfo: action.microcourse,
    };
  }),
  on(MicrocourseActions.getInfoFailure, (state, action) => {
    console.log(action.type);
    return <MicrocourseState>{
      ...state,
      isGettingInfo: false,
      getInfoErrorMessage: action.errorMessage,
    };
  }),
  on(MicrocourseActions.clearMicrocourseInfoStates, (state, action) => {
    return <MicrocourseState>{
      ...state,
      isGettingInfo: false,
      getInfoErrorMessage: '',
      microcourseInfo: <Microcourse>{},
    };
  }),

  //for user
  on(MicrocourseActions.getEnrolledById, (state, action) => {
    console.log(action.type);
    return <MicrocourseState>{
      ...state,
      isGettingEnrolled: true,
      getEnrolledErrorMessage: '',
    };
  }),
  on(MicrocourseActions.getEnrolledByIdSuccess, (state, action) => {
    console.log(action.type);
    return <MicrocourseState>{
      ...state,
      isGettingEnrolled: false,
      enrolledMicrocourses: [...state.enrolledMicrocourses, action.microcourse],
    };
  }),
  on(MicrocourseActions.getEnrolledByIdFailure, (state, action) => {
    console.log(action.type);
    return <MicrocourseState>{
      ...state,
      isGettingEnrolled: false,
      getEnrolledErrorMessage: action.errorMessage,
    };
  }),
  on(MicrocourseActions.clearEnrolledCourse, (state, action) => {
    return <MicrocourseState>{
      ...state,
      enrolledMicrocourses: [],
    };
  }),

  //admin - creator
  on(MicrocourseActions.getByUserPayload, (state, action) => {
    console.log(action.type);
    return <MicrocourseState>{
      ...state,
      isGettingUserManagementMicrocourse: true,
      getUserManagementMicrocourseErrorMessage: '',
    };
  }),
  on(MicrocourseActions.getByUserPayloadSuccess, (state, action) => {
    console.log(action.type);
    return <MicrocourseState>{
      ...state,
      isGettingUserManagementMicrocourse: false,
      userManagementMicrocourses: [
        ...state.userManagementMicrocourses,
        action.microcourse,
      ],
    };
  }),
  on(MicrocourseActions.getByUserPayloadFailure, (state, action) => {
    console.log(action.type);
    return <MicrocourseState>{
      ...state,
      isGettingUserManagementMicrocourse: false,
      getUserManagementMicrocourseErrorMessage: action.errorMessage,
    };
  }),
  on(MicrocourseActions.GetPagination, (state) => {
    return {
      ...state,
      userManagementMicrocourses: [],
    };
  }),

  //search
  on(MicrocourseActions.search, (state, action) => {
    console.log(action.type);
    return <MicrocourseState>{
      ...state,
      searchMicrocourseResult: [],
      isSearching: true,
      searchErrorMessage: '',
    };
  }),
  on(MicrocourseActions.searchSuccess, (state, action) => {
    console.log(action.type);
    return <MicrocourseState>{
      ...state,
      isSearching: false,
      searchMicrocourseResult: action.microcourses,
    };
  }),
  on(MicrocourseActions.searchFailure, (state, action) => {
    console.log(action.type);
    return <MicrocourseState>{
      ...state,
      isSearching: false,
      searchErrorMessage: action.errorMessage,
    };
  }),
  on(MicrocourseActions.clearMicrocourseSearchStates, (state, action) => {
    return <MicrocourseState>{
      ...state,
      isSearching: false,
      searchMicrocourseResult: [],
      searchErrorMessage: '',
    };
  }),
  on(MicrocourseActions.getCourseOfSkillsetInfo, (state, action) => {
    console.log(action.type);
    return <MicrocourseState>{
      ...state,
      isGettingCourseOfSkillsetInfo: true,
      getCourseOfSkillsetInfoErrorMessage: '',
    };
  }),
  on(MicrocourseActions.getCourseOfSkillsetInfoSuccess, (state, action) => {
    console.log(action.type);
    return <MicrocourseState>{
      ...state,
      isGettingCourseOfSkillsetInfo: false,
      courseOfSkillsetInfoList: [
        ...state.courseOfSkillsetInfoList,
        action.microcourse,
      ],
    };
  }),
  on(MicrocourseActions.getCourseOfSkillsetInfoFailure, (state, action) => {
    console.log(action.type);
    return <MicrocourseState>{
      ...state,
      isGettingCourseOfSkillsetInfo: false,
      getCourseOfSkillsetInfoErrorMessage: action.errorMessage,
    };
  }),
  on(MicrocourseActions.clearMicrocourseOfSkillsetStates, (state, action) => {
    return <MicrocourseState>{
      ...state,
      isGettingCourseOfSkillsetInfo: false,
      getCourseOfSkillsetInfoErrorMessage: '',
      courseOfSkillsetInfoList: [],
    };
  }),

  on(MicrocourseActions.getEmbedContent, (state, action) => {
    console.log(action.type);
    return <MicrocourseState>{
      ...state,
      isGettingEmbedContent: true,
      getEmbedContentErrorMessage: '',
    };
  }),
  on(MicrocourseActions.getEmbedContentSuccess, (state, action) => {
    console.log(action.type);
    return <MicrocourseState>{
      ...state,
      isGettingEmbedContent: false,
      embedContent: action.embedContent,
    };
  }),
  on(MicrocourseActions.getEmbedContentFailure, (state, action) => {
    console.log(action.type);
    return <MicrocourseState>{
      ...state,
      isGettingEmbedContent: false,
      getEmbedContentErrorMessage: action.errorMessage,
    };
  }),
);
