import * as SkillsetActions from './skillset.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, of, concatMap } from 'rxjs';
import {
  Certificate,
  MicrocourseGroup,
  MicrocourseGroupResponse,
} from 'src/app/models/skillset.model';
import { SkillsetService } from 'src/app/services/skillset/skillset.service';
import { objectToCamel } from 'src/app/utils/objectTransform';

@Injectable()
export class SkillsetEffects {
  constructor(
    private actions$: Actions,
    private skillsetService: SkillsetService,
  ) {}

  //microcourse_group
  getSkillsetById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SkillsetActions.getSkillsetById),
      switchMap((action) => {
        return this.skillsetService.getSkillsetById(action.id).pipe(
          map((data) => {
            let skillsetData = <MicrocourseGroup>objectToCamel(data);
            skillsetData.contentJson = JSON.parse(skillsetData.content);

            if (skillsetData.content) {
            } else {
              skillsetData.contentJson = {
                time: 0,
                blocks: [],
                version: '',
              };
            }
            return SkillsetActions.getSkillsetByIdSuccess({
              skillsetData,
            });
          }),
          catchError((error) => {
            return of(
              SkillsetActions.getSkillsetByIdFailure({
                errorMessage: error,
              }),
            );
          }),
        );
      }),
    );
  });

  getSkillsetPublishStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SkillsetActions.getSkillsetPublishStatus),
      switchMap((action) => {
        return this.skillsetService.getSkillsetPublishStatus(action.id).pipe(
          map((data) => {
            return SkillsetActions.getSkillsetPublishStatusSuccess({
              publishStatus: data,
            });
          }),
          catchError((error) => {
            return of(
              SkillsetActions.getSkillsetPublishStatusFailure({
                errorMessage: error,
              }),
            );
          }),
        );
      }),
    );
  });

  searchSkillset$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SkillsetActions.searchSkillset),
      switchMap((action) => {
        return this.skillsetService.searchSkillset(action.query).pipe(
          map((data) => {
            let result: MicrocourseGroup[] = [];
            if (data == null) {
              return SkillsetActions.searchSkillsetSuccess({
                searchResult: result,
              });
            } else {
              (data as Array<MicrocourseGroup>).forEach((element) => {
                let microcourseGroup = <MicrocourseGroup>objectToCamel(element);
                result.push(microcourseGroup);
              });
              return SkillsetActions.searchSkillsetSuccess({
                searchResult: result,
              });
            }
          }),
          catchError((error) => {
            return of(
              SkillsetActions.searchSkillsetFailure({
                errorMessage: error,
              }),
            );
          }),
        );
      }),
    );
  });

  listInternalSkillset$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SkillsetActions.listInternalSkillset),
      switchMap((action) => {
        return this.skillsetService
          .listInternalSkillset(action.page, action.size)
          .pipe(
            map((data) => {
              return SkillsetActions.listInternalSkillsetSuccess({
                skillsetListResponse: <MicrocourseGroupResponse>(
                  objectToCamel(data)
                ),
              });
            }),
            catchError((error) => {
              return of(
                SkillsetActions.listInternalSkillsetFailure({
                  errorMessage: error,
                }),
              );
            }),
          );
      }),
    );
  });

  listPublishedSkillset$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SkillsetActions.listPublishedSkillset),
      switchMap((action) => {
        return this.skillsetService
          .listPublishedSkillset(action.page, action.size)
          .pipe(
            map((data) => {
              return SkillsetActions.listPublishedSkillsetSuccess({
                skillsetListResponse: <MicrocourseGroupResponse>(
                  objectToCamel(data)
                ),
              });
            }),
            catchError((error) => {
              return of(
                SkillsetActions.listPublishedSkillsetFailure({
                  errorMessage: error,
                }),
              );
            }),
          );
      }),
    );
  });

  //certificate
  requestCertForIssuing$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SkillsetActions.requestCertForIssuing),
      switchMap((action) => {
        return this.skillsetService
          .requestCertForIssuing(action.skillsetId)
          .pipe(
            map(() => {
              return SkillsetActions.requestCertForIssuingSuccess();
            }),
            catchError((error) => {
              return of(
                SkillsetActions.requestCertForIssuingFailure({
                  errorMessage: error,
                }),
              );
            }),
          );
      }),
    );
  });

  getCertBySkillsetIdAndUserId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SkillsetActions.getCertByUserIdAndSkillsetId),
      switchMap((action) => {
        return this.skillsetService
          .getCertBySkillsetIdAndUserId(action.skillsetId, action.userId)
          .pipe(
            map((data) => {
              return SkillsetActions.getCertByUserIdAndSkillsetIdSuccess({
                certificate: <Certificate>objectToCamel(data),
              });
            }),
            catchError((error) => {
              return of(
                SkillsetActions.getCertByUserIdAndSkillsetIdFailure({
                  errorMessage: error,
                }),
              );
            }),
          );
      }),
    );
  });

  getCertByUserId = createEffect(() => {
    return this.actions$.pipe(
      ofType(SkillsetActions.getCertsByUserId),
      switchMap((action) => {
        return this.skillsetService.getCertsByUserId(action.id).pipe(
          map((data) => {
            return SkillsetActions.getCertsByUserIdSuccess({
              certificates: <Certificate[]>objectToCamel(data),
            });
          }),
          catchError((error) => {
            return of(
              SkillsetActions.getCertsByUserIdFailure({
                errorMessage: error,
              }),
            );
          }),
        );
      }),
    );
  });

  countCertBySkillsetId = createEffect(() => {
    return this.actions$.pipe(
      ofType(SkillsetActions.countCertBySkillsetId),
      concatMap((action) => {
        // console.log(action.skillsetId)
        return this.skillsetService
          .countCertBySkillsetId(action.skillsetId)
          .pipe(
            map((data) => {
              console.log(data);
              return SkillsetActions.countCertBySkillsetIdSuccess({
                count: data,
              });
            }),
            catchError((error) => {
              return of(
                SkillsetActions.countCertBySkillsetIdFailure({
                  errorMessage: error,
                }),
              );
            }),
          );
      }),
    );
  });
}
