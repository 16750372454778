import { Injectable } from '@angular/core';
import { Voucher } from 'src/app/models/voucher.model';
import { HttpClientWithAuth } from '../../utils/HttpClientWithAuth';

@Injectable({
  providedIn: 'root',
})
export class VoucherService {
  constructor(private http: HttpClientWithAuth) {}

  check(voucher: Voucher) {
    return this.http.post('/voucher/check', voucher);
  }

  redeem(voucher: Voucher) {
    return this.http.post('/voucher/redeem', voucher);
  }
}
