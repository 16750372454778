import { Injectable } from '@angular/core';
import * as QuizActions from './quiz.actions';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { QuizService } from 'src/app/services/quiz/quiz.service';
import { catchError, concatMap, map, mergeMap, of, switchMap } from 'rxjs';
import { QuizAttempt, QuizManifest } from 'src/app/models/quiz.model';
import { camelToSnake, objectToCamel } from 'src/app/utils/objectTransform';
import { QuizQuestion } from 'src/app/models/question.model';

@Injectable()
export class QuizEffects {
  constructor(
    private actions$: Actions,
    private quizService: QuizService,
  ) {}

  getByCourseId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuizActions.getByCourseId),
      switchMap((action) => {
        return this.quizService.getByCourseId(action.courseId).pipe(
          map((data) => {
            return QuizActions.getByCourseIdSuccess({
              manifest: <QuizManifest>objectToCamel(data),
            });
          }),
          catchError((error) => {
            return of(
              QuizActions.getByCourseIdFailure({ errorMessage: error }),
            );
          }),
        );
      }),
    );
  });

  getQuestionById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuizActions.getQuestionById),
      switchMap((action) => {
        return this.quizService.getQuestionById(action.id).pipe(
          map((data) => {
            return QuizActions.getQuestionByIdSuccess({
              question: <QuizQuestion>objectToCamel(data),
            });
          }),
          catchError((error) => {
            return of(
              QuizActions.getQuestionByIdFailure({ errorMessage: error }),
            );
          }),
        );
      }),
    );
  });

  getQuestionsByCourseId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuizActions.getQuestionsByCourseId),
      switchMap((action) => {
        return this.quizService.getQuestionByCourseId(action.courseId).pipe(
          map((data) => {
            //parse question json
            let questionList = <QuizQuestion[]>objectToCamel(data);
            questionList = questionList.map((question) => {
              return {
                ...question,
                creating: false,
                questionJson: JSON.parse(question.question) as any,
              };
            });

            return QuizActions.getQuestionsByCourseIdSuccess({
              questionList: questionList,
            });
          }),
          catchError((error) => {
            return of(
              QuizActions.getQuestionsByCourseIdFailure({
                errorMessage: error,
              }),
            );
          }),
        );
      }),
    );
  });

  createAttempt$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuizActions.createAttempt),
      switchMap((action) => {
        return this.quizService
          .createAttempt(action.quizId, action.attempt)
          .pipe(
            map((result) => {
              let attempt = <QuizAttempt>objectToCamel(result);
              //parse question json
              attempt.answerSheet.questions = attempt.answerSheet.questions.map(
                (question) => {
                  return {
                    ...question,
                    questionJson: JSON.parse(question.question) as any,
                  };
                },
              );
              return QuizActions.createAttemptSuccess({
                attempt,
              });
            }),
            catchError((error) => {
              return of(
                QuizActions.createAttemptFailure({ errorMessage: error }),
              );
            }),
          );
      }),
    );
  });

  submitAttempt$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuizActions.submitAttempt),
      switchMap((action) => {
        return this.quizService
          .submitAttempt(camelToSnake(action.answerSheet), action.attemptId)
          .pipe(
            map(() => {
              return QuizActions.submitAttemptSuccess();
            }),
            catchError((error) => {
              return of(
                QuizActions.submitAttemptFailure({ errorMessage: error }),
              );
            }),
          );
      }),
    );
  });

  getMineAttempt$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuizActions.getMineAttempts),
      switchMap((action) => {
        return this.quizService.getMineAttemptsByQuizId(action.quizId).pipe(
          map((data) => {
            return QuizActions.getMineAttemptsSuccess({
              attemptList: <QuizAttempt[]>objectToCamel(data),
            });
          }),
          catchError((error) => {
            return of(
              QuizActions.getMineAttemptsFailure({ errorMessage: error }),
            );
          }),
        );
      }),
    );
  });

  getAttemptById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuizActions.getAttemptById),
      switchMap((action) => {
        return this.quizService.getAttemptByQuizId(action.id).pipe(
          map((data) => {
            let attempt = <QuizAttempt>objectToCamel(data);
            //parse question json
            attempt.answerSheet.questions = attempt.answerSheet.questions.map(
              (question) => {
                return {
                  ...question,
                  questionJson: JSON.parse(question.question) as any,
                };
              },
            );
            return QuizActions.getAttemptByIdSuccess({
              attempt,
            });
          }),
          catchError((error) => {
            return of(
              QuizActions.getAttemptByIdFailure({ errorMessage: error }),
            );
          }),
        );
      }),
    );
  });

  getQuestionInAttemptById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuizActions.getQuestionInAttemptById),
      mergeMap((action) => {
        return this.quizService.getQuestionById(action.id).pipe(
          map((data) => {
            let question = <QuizQuestion>objectToCamel(data);
            //parse question json
            question.questionJson = JSON.parse(question.question) as any;
            // console.log(question);
            return QuizActions.getQuestionInAttemptByIdSuccess({
              question,
            });
          }),
          catchError((error) => {
            return of(
              QuizActions.getQuestionInAttemptByIdFailure({
                errorMessage: error,
              }),
            );
          }),
        );
      }),
    );
  });

  getAttemptBestResult$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuizActions.getAttemptBestResult),
      switchMap((action) => {
        return this.quizService.getAttemptBestResult(action.quizId).pipe(
          map((data) => {
            let attempt = <QuizAttempt>objectToCamel(data);
            //parse question json
            attempt.answerSheet.questions = attempt.answerSheet.questions.map(
              (question) => {
                return {
                  ...question,
                  questionJson: JSON.parse(question.question) as any,
                };
              },
            );
            return QuizActions.getAttemptBestResultSuccess({
              attempt,
            });
          }),
          catchError((error) => {
            return of(
              QuizActions.getAttemptBestResultFailure({ errorMessage: error }),
            );
          }),
        );
      }),
    );
  });

  countAttemptsByUserId$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuizActions.countAttemptsByUserId),
      switchMap(() => {
        return this.quizService.countAttemptsByUserId().pipe(
          map((data) => {
            return QuizActions.countAttemptsByUserIdSuccess({
              count: data as number,
            });
          }),
          catchError((error) => {
            return of(
              QuizActions.countAttemptsByUserIdFailure({ errorMessage: error }),
            );
          }),
        );
      }),
    );
  });

  getEmbedContent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(QuizActions.getEmbedContent),
      concatMap((action) => {
        return this.quizService
          .getEmbedContentByContentJson(action.contentJson)
          .pipe(
            map((data: string) => {
              return QuizActions.getEmbedContentSuccess({
                embedContentByContentJson: data,
              });
            }),
            catchError((error) => {
              return of(
                QuizActions.getEmbedContentErrorMessage({
                  errorMessage: error,
                }),
              );
            }),
          );
      }),
    );
  });
}
