import { Injectable } from '@angular/core';
import { HttpClientWithAuth } from '../../utils/HttpClientWithAuth';

@Injectable({
  providedIn: 'root',
})
export class SkillService {
  constructor(private http: HttpClientWithAuth) {}

  //skill
  getSkillById(id: string) {
    return this.http.get(`/mentor/skill?id=${id}`);
  }

  listSkill(size: number, page: number) {
    return this.http.get(`/mentor/skill/list?size=${size}&page=${page}`);
  }

  listSkillByCategoryId(size: number, page: number, categoryId: string) {
    return this.http.get(
      `/mentor/skill/list_by_category_id?size=${size}&page=${page}&category_id=${categoryId}`,
    );
  }

  //skill category
  listSkillCategory(size: number, page: number) {
    return this.http.get(
      `/mentor/skill_category/list?size=${size}&page=${page}`,
    );
  }

  getSkillCategoryById(id: string) {
    return this.http.get(`/mentor/skill_category?id=${id}`);
  }
}
