import * as SkillActions from './skill.actions';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, of } from 'rxjs';
import { SkillService } from 'src/app/services/skill/skill.service';
import { objectToCamel } from 'src/app/utils/objectTransform';

@Injectable()
export class SkillEffects {
  constructor(
    private actions$: Actions,
    private skill: SkillService,
  ) {}

  //category
  getSkillCategoryById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SkillActions.getSkillCategoryById),
      switchMap((action) => {
        return this.skill.getSkillCategoryById(action.id).pipe(
          map((response: any) => {
            const skillCategory = objectToCamel(response);
            return SkillActions.getSkillCategoryByIdSuccess({ skillCategory });
          }),
          catchError((error) => {
            return of(
              SkillActions.getSkillCategoryByIdFailure({
                errorMessage: error.error.message,
              }),
            );
          }),
        );
      }),
    ),
  );

  listSkillCategory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SkillActions.listSkillCategory),
      switchMap((action) => {
        return this.skill.listSkillCategory(action.size, action.page).pipe(
          map((response: any) => {
            const skillCategoryResponse = objectToCamel(response);
            return SkillActions.listSkillCategorySuccess({
              skillCategoryResponse,
            });
          }),
          catchError((error) => {
            return of(
              SkillActions.listSkillCategoryFailure({
                errorMessage: error.error.message,
              }),
            );
          }),
        );
      }),
    ),
  );

  //skill
  getSkillById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SkillActions.getSkillById),
      switchMap((action) => {
        return this.skill.getSkillById(action.id).pipe(
          map((response: any) => {
            const skill = objectToCamel(response);
            return SkillActions.getSkillByIdSuccess({ skill });
          }),
          catchError((error) => {
            return of(
              SkillActions.getSkillByIdFailure({
                errorMessage: error.error.message,
              }),
            );
          }),
        );
      }),
    ),
  );

  listSkill$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SkillActions.listSkill),
      switchMap((action) => {
        return this.skill.listSkill(action.size, action.page).pipe(
          map((response: any) => {
            const skillResponse = objectToCamel(response);
            return SkillActions.listSkillSuccess({ skillResponse });
          }),
          catchError((error) => {
            return of(
              SkillActions.listSkillFailure({
                errorMessage: error.error.message,
              }),
            );
          }),
        );
      }),
    ),
  );

  listSkillByCategoryId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SkillActions.listSkillByCategoryId),
      switchMap((action) => {
        return this.skill
          .listSkillByCategoryId(action.size, action.page, action.categoryId)
          .pipe(
            map((response: any) => {
              let skillResponse = objectToCamel(response);
              return SkillActions.listSkillByCategoryIdSuccess({
                skillResponse,
              });
            }),
            catchError((error) => {
              return of(
                SkillActions.listSkillByCategoryIdFailure({
                  errorMessage: error.error.message,
                }),
              );
            }),
          );
      }),
    ),
  );
}
