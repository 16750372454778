import { createReducer, on } from '@ngrx/store';
import * as SkillActions from './skill.actions';
import { SkillState } from './skill.state';
import {
  SkillResponse,
  Skill,
  SkillCategory,
  SkillCategoryResponse,
} from 'src/app/models/skill.model';

const initialState: SkillState = {
  skillResponse: <SkillResponse>{},
  isListingSkillReponseById: false,
  listSkillReponseByIdErrorMessage: '',

  skillsOfMentor: [],
  isGettingSkillById: false,
  getSkillByIdErrorMessage: '',

  //category
  isListingSkillResponseByCategoryId: false,
  skillByCategoryIdResponse: <SkillResponse>{},
  listSkillResponseByCategoryIdErrorMessage: '',

  skillCategoryResponse: <SkillCategoryResponse>{},
  isListingSkillCategoryResponse: false,
  listSkillCategoryReponseErrorMessage: '',

  skillCategroy: <SkillCategory>{},
  isGettingSkillCategoryById: false,
  getSkillCategoryByIdErrorMessage: '',
};

export const skillReducer = createReducer(
  initialState,

  //category
  on(SkillActions.getSkillCategoryById, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isGettingSkillCategoryById: true,
      getSkillCategoryByIdErrorMessage: '',
      skillCategroy: <SkillCategory>{},
    };
  }),
  on(SkillActions.getSkillCategoryByIdSuccess, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isGettingSkillCategoryById: false,
      skillCategroy: action.skillCategory,
    };
  }),
  on(SkillActions.getSkillCategoryByIdFailure, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isGettingSkillCategoryById: false,
      getSkillCategoryByIdErrorMessage: action.errorMessage,
    };
  }),

  on(SkillActions.listSkillCategory, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isListingSkillCategoryResponse: true,
      listSkillCategoryReponseErrorMessage: '',
      skillCategoryResponse: <SkillCategoryResponse>{},
    };
  }),
  on(SkillActions.listSkillCategorySuccess, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isListingSkillCategoryResponse: false,
      skillCategoryResponse: action.skillCategoryResponse,
    };
  }),
  on(SkillActions.listSkillCategoryFailure, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isListingSkillCategoryResponse: false,
      listSkillCategoryReponseErrorMessage: action.errorMessage,
    };
  }),

  //skill
  on(SkillActions.getSkillById, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isGettingSkillById: true,
      getSkillByIdErrorMessage: '',
    };
  }),
  on(SkillActions.getSkillByIdSuccess, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isGettingSkillById: false,
      skillsOfMentor: [...state.skillsOfMentor, action.skill],
    };
  }),
  on(SkillActions.getSkillByIdFailure, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isGettingSkillById: false,
      getSkillByIdErrorMessage: action.errorMessage,
    };
  }),

  on(SkillActions.listSkillByCategoryId, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isListingSkillResponseByCategoryId: true,
      listSkillResponseByCategoryIdErrorMessage: '',
      skillByCategoryIdResponse: <SkillResponse>{},
    };
  }),
  on(SkillActions.listSkillByCategoryIdSuccess, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isListingSkillResponseByCategoryId: false,
      skillByCategoryIdResponse: action.skillResponse,
    };
  }),
  on(SkillActions.listSkillByCategoryIdFailure, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isListingSkillResponseByCategoryId: false,
      listSkillResponseByCategoryIdErrorMessage: action.errorMessage,
    };
  }),

  on(SkillActions.listSkill, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isListingSkillReponseById: true,
      listSkillReponseByIdErrorMessage: '',
      skillResponse: <SkillResponse>{},
    };
  }),
  on(SkillActions.listSkillSuccess, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isListingSkillReponseById: false,
      skillResponse: action.skillResponse,
    };
  }),
  on(SkillActions.listSkillFailure, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isListingSkillReponseById: false,
      listSkillReponseByIdErrorMessage: action.errorMessage,
    };
  }),

  on(SkillActions.clearListSkillByCategoryIdActionState, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isListingSkillResponseByCategoryId: false,
      listSkillResponseByCategoryIdErrorMessage: '',
      skillByCategoryIdResponse: <SkillResponse>{},
    };
  }),

  //skill
  on(SkillActions.clearListSkillByCategoryIdActionState, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isListingSkillResponseByCategoryId: false,
      listSkillResponseByCategoryIdErrorMessage: '',
      skillByCategoryIdResponse: <SkillResponse>{},
    };
  }),
  on(SkillActions.clearListSkillActionState, (state, action) => {
    console.log(action.type);
    return <SkillState>{
      ...state,
      isListingSkillReponseById: false,
      listSkillReponseByIdErrorMessage: '',
      skillResponse: <SkillResponse>{},
    };
  }),
);
