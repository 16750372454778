import { createAction, props } from '@ngrx/store';
import {
  Skill,
  SkillCategory,
  SkillResponse,
} from 'src/app/models/skill.model';

//category
export const getSkillCategoryById = createAction(
  '[Skill Category] Get by Id',
  props<{ id: string }>(),
);

export const getSkillCategoryByIdSuccess = createAction(
  '[Skill Category] Get by Id Success',
  props<{ skillCategory: SkillCategory }>(),
);

export const getSkillCategoryByIdFailure = createAction(
  '[Skill Category] Get by Id Failure',
  props<{ errorMessage: string }>(),
);

export const listSkillCategory = createAction(
  '[Skill Category] List',
  props<{ size: number; page: number }>(),
);

export const listSkillCategorySuccess = createAction(
  '[Skill Category] List Success',
  props<{ skillCategoryResponse: SkillResponse }>(),
);

export const listSkillCategoryFailure = createAction(
  '[Skill Category] List Failure',
  props<{ errorMessage: string }>(),
);

//skill
export const getSkillById = createAction(
  '[Skill] Get by Id',
  props<{ id: string }>(),
);

export const getSkillByIdSuccess = createAction(
  '[Skill] Get by Id Success',
  props<{ skill: Skill }>(),
);

export const getSkillByIdFailure = createAction(
  '[Skill] Get by Id Failure',
  props<{ errorMessage: string }>(),
);

export const listSkill = createAction(
  '[Skill] List',
  props<{ size: number; page: number }>(),
);

export const listSkillSuccess = createAction(
  '[Skill] List Success',
  props<{ skillResponse: SkillResponse }>(),
);

export const listSkillFailure = createAction(
  '[Skill] List Failure',
  props<{ errorMessage: string }>(),
);

export const listSkillByCategoryId = createAction(
  '[Skill] List by Category Id',
  props<{ categoryId: string; page: number; size: number }>(),
);

export const listSkillByCategoryIdSuccess = createAction(
  '[Skill] List by Category Id Success',
  props<{ skillResponse: SkillResponse }>(),
);

export const listSkillByCategoryIdFailure = createAction(
  '[Skill] List by Category Id Failure',
  props<{ errorMessage: string }>(),
);

export const clearListSkillByCategoryIdActionState = createAction(
  '[Skill] Clear List by Category Id Action State',
);

export const clearListSkillActionState = createAction(
  '[Skill] Clear List Action State',
);
