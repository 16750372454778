import {Injectable} from '@angular/core';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AnalyticService {

  constructor() {
  }

  initializeGA() {
    const isProduction = environment.production;
    const domain = window.location.hostname;
    const domainMap = environment.domain as { [key: string]: string };
    const analyticCodeMap = environment.analytics as { [key: string]: string };

    const countryKey = Object.keys(domainMap).find(key => domainMap[key] === domain);
    if (!isProduction || !countryKey || !domainMap[countryKey]) {
      console.log('No GA tag for this domain');
      return;
    }
    // add ga tag in to head
    const script = document.createElement('script');
    console.log('Add GA tag for domain: ', domain);
    console.log('Add GA tag for country: ', analyticCodeMap[countryKey], countryKey);
    script.src = `https://www.googletagmanager.com/gtag/js?id=${analyticCodeMap[countryKey]}`;
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer = window.dataLayer || [];

    function gtag() {
      window.dataLayer.push(arguments);
    }

    // @ts-ignore
    gtag('js', new Date());
    // @ts-ignore
    gtag('config', analyticCodeMap[countryKey]);
  }


}
