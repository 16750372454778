import { AuthState } from './auth.state';
import { createReducer, on } from '@ngrx/store';
import {
  FirebaseUser,
  StaticUserResponse,
  User,
  UserResponse,
} from 'src/app/models/user.model';
import * as AuthActions from './auth.actions';

const initialState: AuthState = {
  isSignInWithGgSuccess: false,
  isSigningInWithGg: false,
  signInWithGgErrorMessage: '',

  userCredential: <User>{},
  isSigningInWithSystemGg: false,
  signInWithSystemGgErrorMessage: '',

  isSigningUpWithSystemGg: false,
  isSignUpWithSystemGgSuccess: false,
  signUpWithSystemGgErrorMessage: '',

  //store value
  idToken: '',
  firebaseUser: <FirebaseUser>{},
  jwt: '',
};

export const authReducer = createReducer(
  initialState,
  // signInWithGoogle
  on(AuthActions.signInWithGoogle, (state, action) => {
    console.log(action.type);
    return <AuthState>{
      ...state,
      isSigningInWithGg: true,
      isSignInWithGgSuccess: false,
      signInWithGgErrorMessage: '',
    };
  }),
  on(AuthActions.signInWithGoogleSuccess, (state, action) => {
    console.log(action.type);
    return <AuthState>{
      ...state,
      isSigningInWithGg: false,
      isSignInWithGgSuccess: true,
    };
  }),
  on(AuthActions.signInWithGoogleFailure, (state, action) => {
    console.log(action.type);
    return <AuthState>{
      ...state,
      isSigningInWithGg: false,
      signInWithGgErrorMessage: action.errorMessage,
    };
  }),

  // signInWithSystemGG
  on(AuthActions.signInWithSystemGG, (state, { type }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      isSigningInWithSystemGg: true,
      signInWithSystemGgErrorMessage: '',
      userCredential: <User>{},
    };
  }),
  on(
    AuthActions.signInWithSystemGGSuccess,
    (state, { type, userCredential }) => {
      console.log(type);
      return <AuthState>{
        ...state,
        isSigningInWithSystemGg: false,
        userCredential: userCredential,
      };
    },
  ),
  on(AuthActions.signInWithSystemGGFailure, (state, { type, errorMessage }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      isSigningInWithSystemGg: false,
      signInWithSystemGgErrorMessage: errorMessage,
    };
  }),

  // signUpWithSystemGG
  on(AuthActions.signUpWithSystemGG, (state, { type }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      isSigningUpWithSystemGg: true,
      isSignUpWithSystemGgSuccess: false,
      signUpWithSystemGgErrorMessage: '',
    };
  }),
  on(AuthActions.signUpWithSystemGGSuccess, (state, action) => {
    console.log(action.type);
    return <AuthState>{
      ...state,
      isSigningUpWithSystemGg: false,
      isSignUpWithSystemGgSuccess: true,
    };
  }),
  on(AuthActions.signUpWithSystemGGFailure, (state, { type, errorMessage }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      isSigningUpWithSystemGg: false,
      signUpWithSystemGgErrorMessage: errorMessage,
    };
  }),

  // storeIdToken
  on(AuthActions.storeIdToken, (state, { type, idToken }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      idToken: idToken,
    };
  }),

  // storeFirebaseUser
  on(AuthActions.storeFirebaseUser, (state, { type, firebaseUser }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      firebaseUser: firebaseUser,
    };
  }),

  // storeJWT
  on(AuthActions.storeJWT, (state, { type, jwt }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      jwt: jwt,
    };
  }),

  // clearAllState
  on(AuthActions.clearState, (state, { type }) => {
    console.log(type);
    return <AuthState>{
      ...state,
      isSignInWithGgSuccess: false,
      isSigningInWithGg: false,
      signInWithGgErrorMessage: '',

      userCredential: <User>{},
      isSigningInWithSystemGg: false,
      signInWithSystemGgErrorMessage: '',

      isSigningUpWithSystemGg: false,
      isSignUpWithSystemGgSuccess: false,
      signUpWithSystemGgErrorMessage: '',

      //store value
      idToken: '',
      firebaseUser: <FirebaseUser>{},
      jwt: '',

      //check sign in status
      isAlreadySignIn: false,
    };
  }),

  // clearMessages
  on(AuthActions.clearMessages, (state) => {
    return <AuthState>{
      ...state,
      isSignInWithGgSuccess: false,
      isSigningInWithGg: false,
      signInWithGgErrorMessage: '',

      isSigningInWithSystemGg: false,
      isSignInWithSystemGgSuccess: false,
      signInWithSystemGgErrorMessage: '',

      isSigningUpWithSystemGg: false,
      isSignUpWithSystemGgSuccess: false,
      signUpWithSystemGgErrorMessage: '',
    };
  }),
);
