import { createReducer, on } from '@ngrx/store';
import { SkillsetState } from './skillset.state';
import {
  Certificate,
  MicrocourseGroup,
  MicrocourseGroupResponse,
} from 'src/app/models/skillset.model';
import * as SkillsetActions from './skillset.actions';

const initialState: SkillsetState = {
  //microcourse_group
  internalSkillsetListResponse: <MicrocourseGroupResponse>{},
  isListingInternalSkilletResponse: false,
  listInternalSkilletResponseErrorMessage: '',

  publishedSkillsetListResponse: <MicrocourseGroupResponse>{},
  isListingPublishedSkilletResponse: false,
  listPublishedSkilletResponseErrorMessage: '',

  skillset: <MicrocourseGroup>{},
  isGettingSkillsetById: false,
  getSkillsetByIdErrorMessage: '',

  isSearchingSkillset: false,
  skillsetSearchResult: [],
  searchSkillsetErrorMessage: '',

  isGettingSkillsetPublishStatus: false,
  skillsetPublishStatus: false,
  getSkillsetPublishStatusErrorMessage: '',

  //certificate
  isRequestingCertForIssuing: false,
  isRequestCertForIssuingSuccess: false,
  requestCertForIssuingErrorMessage: '',

  isCoutingCertBySkillsetId: false,
  certificateCount: 0,
  countCertBySkillsetIdErrorMessage: '',

  isGettingCertByUserIdAndSkillsetId: false,
  certificate: <Certificate>{},
  getCertByUserIdAndSkillsetIdErrorMessage: '',

  isGettingCertByUserId: false,
  certificates: [],
  getCertByUserIdErrorMessage: '',
};

export const skillsetReducer = createReducer(
  initialState,

  //microcourse_group
  on(SkillsetActions.listInternalSkillset, (state, action) => {
    console.log(action.type);
    return <SkillsetState>{
      ...state,
      isListingInternalSkilletResponse: true,
      internalSkillsetListResponse: <MicrocourseGroupResponse>{},
      listInternalSkilletResponseErrorMessage: '',
    };
  }),
  on(SkillsetActions.listInternalSkillsetSuccess, (state, action) => {
    console.log(action.type);
    return <SkillsetState>{
      ...state,
      isListingInternalSkilletResponse: false,
      internalSkillsetListResponse: action.skillsetListResponse,
    };
  }),
  on(SkillsetActions.listInternalSkillsetFailure, (state, action) => {
    console.log(action.type);
    return <SkillsetState>{
      ...state,
      isListingInternalSkilletResponse: false,
      listInternalSkilletResponseErrorMessage: action.errorMessage,
    };
  }),

  on(SkillsetActions.listPublishedSkillset, (state, action) => {
    console.log(action.type);
    return <SkillsetState>{
      ...state,
      isListingPublishedSkilletResponse: true,
      publishedSkillsetListResponse: <MicrocourseGroupResponse>{},
      listPublishedSkilletResponseErrorMessage: '',
    };
  }),
  on(SkillsetActions.listPublishedSkillsetSuccess, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isListingPublishedSkilletResponse: false,
      publishedSkillsetListResponse: action.skillsetListResponse,
    };
  }),
  on(SkillsetActions.listPublishedSkillsetFailure, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isListingPublishedSkilletResponse: false,
      listPublishedSkilletResponseErrorMessage: action.errorMessage,
    };
  }),

  on(SkillsetActions.getSkillsetById, (state, action) => {
    console.log(action.type);
    return <SkillsetState>{
      ...state,
      isGettingSkillsetById: true,
      skillset: <MicrocourseGroup>{},
      getSkillsetByIdErrorMessage: '',
    };
  }),
  on(SkillsetActions.getSkillsetByIdSuccess, (state, action) => {
    console.log(action.type);
    return <SkillsetState>{
      ...state,
      isGettingSkillsetById: false,
      skillset: action.skillsetData,
    };
  }),
  on(SkillsetActions.getSkillsetByIdFailure, (state, action) => {
    console.log(action.type);
    return <SkillsetState>{
      ...state,
      isGettingSkillsetById: false,
      getSkillsetByIdErrorMessage: action.errorMessage,
    };
  }),

  on(SkillsetActions.getSkillsetPublishStatus, (state, action) => {
    console.log(action.type);
    return <SkillsetState>{
      ...state,
      isGettingSkillsetPublishStatus: true,
      skillsetPublishStatus: false,
      getSkillsetPublishStatusErrorMessage: '',
    };
  }),
  on(SkillsetActions.getSkillsetPublishStatusSuccess, (state, action) => {
    console.log(action.type);
    return <SkillsetState>{
      ...state,
      isGettingSkillsetPublishStatus: false,
      skillsetPublishStatus: action.publishStatus,
    };
  }),
  on(SkillsetActions.getSkillsetPublishStatusFailure, (state, action) => {
    console.log(action.type);
    return <SkillsetState>{
      ...state,
      isGettingSkillsetPublishStatus: false,
      getSkillsetPublishStatusErrorMessage: action.errorMessage,
    };
  }),

  on(SkillsetActions.searchSkillset, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isSearchingSkillset: true,
      skillsetSearchResult: [],
      searchSkillsetErrorMessage: '',
    };
  }),
  on(SkillsetActions.searchSkillsetSuccess, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isSearchingSkillset: false,
      skillsetSearchResult: action.searchResult,
    };
  }),
  on(SkillsetActions.searchSkillsetFailure, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isSearchingSkillset: false,
      searchSkillsetErrorMessage: action.errorMessage,
    };
  }),

  //certificate
  on(SkillsetActions.countCertBySkillsetId, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isCoutingCertBySkillsetId: true,
      certificateCount: 0,
      countCertBySkillsetIdErrorMessage: '',
    };
  }),
  on(SkillsetActions.countCertBySkillsetIdSuccess, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isCoutingCertBySkillsetId: false,
      certificateCount: action.count,
    };
  }),
  on(SkillsetActions.countCertBySkillsetIdFailure, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isCoutingCertBySkillsetId: false,
      countCertBySkillsetIdErrorMessage: action.errorMessage,
    };
  }),

  on(SkillsetActions.getCertByUserIdAndSkillsetId, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isGettingCertByUserIdAndSkillsetId: true,
      certificate: <Certificate>{},
      getCertByUserIdAndSkillsetIdErrorMessage: '',
    };
  }),
  on(SkillsetActions.getCertByUserIdAndSkillsetIdSuccess, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isGettingCertByUserIdAndSkillsetId: false,
      certificate: action.certificate,
    };
  }),
  on(SkillsetActions.countCertBySkillsetIdFailure, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isGettingCertByUserIdAndSkillsetId: false,
      getCertByUserIdAndSkillsetIdErrorMessage: action.errorMessage,
    };
  }),

  on(SkillsetActions.getCertsByUserId, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isGettingCertByUserId: true,
      certificates: [],
      getCertByUserIdErrorMessage: '',
    };
  }),
  on(SkillsetActions.getCertsByUserIdSuccess, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isGettingCertByUserId: false,
      certificates: action.certificates,
    };
  }),
  on(SkillsetActions.getCertsByUserIdFailure, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isGettingCertByUserId: false,
      getCertByUserIdErrorMessage: action.errorMessage,
    };
  }),

  on(SkillsetActions.requestCertForIssuing, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isRequestCertForIssuingSuccess: false,
      isRequestingCertForIssuing: true,
      requestCertForIssuingErrorMessage: '',
    };
  }),
  on(SkillsetActions.requestCertForIssuingSuccess, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isRequestingCertForIssuing: false,
      isRequestCertForIssuingSuccess: true,
    };
  }),
  on(SkillsetActions.requestCertForIssuingFailure, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isRequestingCertForIssuing: false,
      requestCertForIssuingErrorMessage: action.errorMessage,
    };
  }),

  on(SkillsetActions.clearGetSkillsetByIdState, (state, action) => {
    // console.log(action.type);
    return {
      ...state,
      isGettingSkillsetById: false,
      getSkillsetByIdErrorMessage: '',
      skillset: <MicrocourseGroup>{},
    };
  }),
  on(
    SkillsetActions.clearGetCertByUserIdAndSkillsetIdState,
    (state, action) => {
      // console.log(action.type);
      return {
        ...state,
        isGettingCertByUserIdAndSkillsetId: false,
        certificate: <Certificate>{},
        getCertByUserIdAndSkillsetIdErrorMessage: '',
      };
    },
  ),
  on(SkillsetActions.clearRequestCertForIssuingState, (state, action) => {
    // console.log(action.type);
    return {
      ...state,
      isRequestingCertForIssuing: false,
      isRequestCertForIssuingSuccess: false,
      requestCertForIssuingErrorMessage: '',
    };
  }),
  on(SkillsetActions.clearListPublishedSkillsetState, (state, action) => {
    // console.log(action.type);
    return {
      ...state,
      isListingPublishedSkilletResponse: false,
      publishedSkillsetListResponse: <MicrocourseGroupResponse>{},
      listPublishedSkilletResponseErrorMessage: '',
    };
  }),
);
