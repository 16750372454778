import { createAction, props } from '@ngrx/store';
import {
  FirebaseUser,
  StaticUser,
  StaticUserResponse,
  User,
  UserResponse,
} from 'src/app/models/user.model';

export const clearState = createAction('[Auth] Clear State');

export const clearMessages = createAction('[Auth] Clear Message');

export const storeIdToken = createAction(
  '[Auth] Store Id Token',
  (idToken: string) => ({ idToken }),
);

export const storeFirebaseUser = createAction(
  '[Auth] Store Firebase User',
  props<{ firebaseUser: FirebaseUser }>(),
);

export const storeJWT = createAction(
  '[Auth] Store JWT',
  props<{ jwt: string }>(),
);

export const signInWithGoogle = createAction('[Auth] Sign in with Google');

export const signInWithGoogleSuccess = createAction(
  '[Auth] Sign in with Google Success',
);

export const signInWithGoogleFailure = createAction(
  '[Auth] Sign in with Google Failure',
  props<{ errorMessage: string }>(),
);

export const signInWithSystemGG = createAction(
  '[Auth] Sign in with System GG',
  props<{ idToken: string }>(),
);

export const signInWithSystemGGSuccess = createAction(
  '[Auth] Sign in with System GG Success',
  props<{ userCredential: User }>(),
);

export const signInWithSystemGGFailure = createAction(
  '[Auth] Sign in with System GG Failure',
  props<{ errorMessage: string }>(),
);

export const signUpWithSystemGG = createAction(
  '[Auth] Sign up with System GG',
  props<{ idToken: string }>(),
);

export const signUpWithSystemGGSuccess = createAction(
  '[Auth] Sign up with System GG Success',
);

export const signUpWithSystemGGFailure = createAction(
  '[Auth] Sign up with System GG Failure',
  props<{ errorMessage: string }>(),
);
