import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { User } from 'src/app/models/user.model';
import { AuthState } from 'src/app/ngrx/auth/auth.state';
import { ProfileState } from 'src/app/ngrx/profile/profile.state';
import { AuthService } from 'src/app/services/auth/auth.service';

import * as AuthActions from '../../../ngrx/auth/auth.actions';
import * as ProfileActions from '../../../ngrx/profile/profile.actions';
import * as LearningProgressActions from '../../../ngrx/learning-progress/learning-progress.actions';
import * as EnrollmentActions from '../../../ngrx/enrollment/enrollment.actions';
import * as UsageTimeActions from '../../../ngrx/usage-time/usage-time.actions';
import * as QuizActions from '../../../ngrx/quiz/quiz.actions';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.scss'],
})
export class SideBarComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output('open') expandEventEmitter = new EventEmitter<boolean>();
  @ViewChild('sidebar', { static: true }) sidebar!: ElementRef;

  userPages = [
    {
      id: 0,
      icon: 'account_circle',
      title: 'Hồ sơ',
      path: 'home',
    },
    {
      id: 1,
      icon: 'explore',
      title: 'Microcourse',
      path: 'explore/microcourses/list',
    },
  ];

  itemSelected: number = 1;

  subscriptions: Subscription[] = [];

  user: User | null = null;

  jwt$ = this.store.select('auth', 'jwt');
  hidden: boolean = true;

  isExpanded = true;

  private resizeObserver!: ResizeObserver;

  constructor(
    private router: Router,
    private store: Store<{
      auth: AuthState;
      profile: ProfileState;
    }>,
    private authService: AuthService,
  ) {}

  ngAfterViewInit() {
    this.resizeObserver = new ResizeObserver(() => {
      this.getSidebarWidth();
    });
    this.resizeObserver.observe(this.sidebar.nativeElement);
  }

  ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.jwt$.subscribe((jwt) => {
        if (jwt) {
          console.log(jwt);
        }
        this.hidden = !jwt;
      }),

      this.router.events.subscribe(() => {
        if (this.router.url.includes('home')) {
          this.itemSelected = 0;
        } else if (this.router.url.includes('explore')) {
          this.itemSelected = 1;
        } else {
          this.itemSelected = -1;
        }
      }),
    );
  }

  getSidebarWidth(): number {
    const width = this.sidebar.nativeElement.offsetWidth;
    this.isExpanded = width > 70;
    return width;
  }

  signOut() {
    this.authService.signOut().then(() => {
      this.router.navigate(['explore/microcourses/list']).then(() => {
        this.store.dispatch(AuthActions.clearState());
        this.clearState();
      });
    });
  }

  clearState() {
    this.store.dispatch(ProfileActions.clearMineProfileStates());
    this.store.dispatch(LearningProgressActions.clearStates());
    this.store.dispatch(EnrollmentActions.clearStates());
    this.store.dispatch(UsageTimeActions.resetStates());
    this.store.dispatch(QuizActions.resetStates());

    this.itemSelected = 1;
  }

  selected(index: number) {
    this.router.navigate([this.userPages[index].path]).then();
  }

  feedback() {
    window.open(
      'https://management.akademy.dev/dashboard/#/nc/form/9e848c8a-16a2-4732-8033-9545d1bd4dea',
      '_blank',
    );
  }

  expand() {
    this.isExpanded = !this.isExpanded;

    this.expandEventEmitter.emit(this.isExpanded);
  }

  handleLogoOnClick() {
    this.router.navigate(['explore/microcourses/list']).then();
  }
}
