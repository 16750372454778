import { createAction, props } from '@ngrx/store';
import { Voucher } from 'src/app/models/voucher.model';

export const check = createAction(
  '[Voucher] Check',
  props<{ voucher: Voucher }>(),
);
export const checkSuccess = createAction(
  '[Voucher] Check Success',
  props<{ voucher: Voucher }>(),
);
export const checkFailure = createAction(
  '[Voucher] Check Failure',
  props<{ errorMessage: string }>(),
);

export const redeem = createAction(
  '[Voucher] Redeem',
  props<{ voucher: Voucher }>(),
);
export const redeemSuccess = createAction('[Voucher] Redeem Success');
export const redeemFailure = createAction(
  '[Voucher] Redeem Failure',
  props<{ errorMessage: string }>(),
);

export const clearStates = createAction('[Voucher] Clear States');
