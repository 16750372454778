import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MicrocourseService } from 'src/app/services/microcourse/microcourse.service';
import { catchError, map, mergeMap, of, switchMap } from 'rxjs';
import { objectToCamel } from 'src/app/utils/objectTransform';
import {
  Microcourse,
  MicrocourseResponse,
} from 'src/app/models/microcourse.model';

import * as MicrocourseActions from './microcourse.actions';

@Injectable()
export class MicrocourseEffects {
  constructor(
    private actions$: Actions,
    private MicrocourseService: MicrocourseService,
  ) {}

  getList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MicrocourseActions.getResponse),
      switchMap((action) => {
        return this.MicrocourseService.getResponse(
          action.page,
          action.size,
        ).pipe(
          map((data) => {
            return MicrocourseActions.getResponseSuccess({
              microcourses: <MicrocourseResponse>objectToCamel(data),
            });
          }),
          catchError((error) => {
            return of(
              MicrocourseActions.getResponseFailure({ errorMessage: error }),
            );
          }),
        );
      }),
    );
  });

  getById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MicrocourseActions.getById),
      switchMap((action) => {
        return this.MicrocourseService.getById(action.id).pipe(
          map((data) => {
            if (data.content == '') {
              data.content = '{}';
            }
            let microcourse = <Microcourse>objectToCamel(data);
            microcourse.contentJson = JSON.parse(microcourse.content);
            return MicrocourseActions.getByIdSuccess({
              microcourse: microcourse,
            });
          }),
          catchError((error) => {
            return of(
              MicrocourseActions.getByIdFailure({ errorMessage: error }),
            );
          }),
        );
      }),
    );
  });

  getEnrolledById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MicrocourseActions.getEnrolledById),
      mergeMap((action) => {
        return this.MicrocourseService.getById(action.id).pipe(
          map((data) => {
            let microcourse = <Microcourse>objectToCamel(data);
            microcourse.contentJson = JSON.parse(microcourse.content);
            return MicrocourseActions.getEnrolledByIdSuccess({
              microcourse: microcourse,
            });
          }),
          catchError((error) => {
            return of(
              MicrocourseActions.getEnrolledByIdFailure({
                errorMessage: error,
              }),
            );
          }),
        );
      }),
    );
  });

  getUserManagementById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MicrocourseActions.getByUserPayload),
      mergeMap((action) => {
        return this.MicrocourseService.getById(action.id).pipe(
          map((data) => {
            if (data.content == '') {
              data.content = '{}';
            }
            let microcourse = <Microcourse>objectToCamel(data);
            microcourse.contentJson = JSON.parse(microcourse.content);

            return MicrocourseActions.getByUserPayloadSuccess({
              microcourse: microcourse,
            });
          }),
          catchError((error) => {
            return of(
              MicrocourseActions.getByUserPayloadFailure({
                errorMessage: error,
              }),
            );
          }),
        );
      }),
    );
  });

  search$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MicrocourseActions.search),
      switchMap((action) => {
        return this.MicrocourseService.search(action.query).pipe(
          // delay(1000),
          map((data) => {
            let result: Microcourse[] = [];
            if (data === null) {
              return MicrocourseActions.searchSuccess({
                microcourses: result,
              });
            } else {
              (data as Array<Microcourse>).forEach((element) => {
                let microcourse = <Microcourse>objectToCamel(element);
                result.push(microcourse);
              });
              return MicrocourseActions.searchSuccess({
                microcourses: result,
              });
            }
          }),
          catchError((error) => {
            // console.log(error);
            return of(
              MicrocourseActions.searchFailure({ errorMessage: error }),
            );
          }),
        );
      }),
    );
  });

  getInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MicrocourseActions.getInfo),
      switchMap((action) => {
        return this.MicrocourseService.getInfo(action.id).pipe(
          map((data) => {
            let microcourse = <Microcourse>objectToCamel(data);
            return MicrocourseActions.getInfoSuccess({
              microcourse: microcourse,
            });
          }),
          catchError((error) => {
            return of(
              MicrocourseActions.getInfoFailure({ errorMessage: error }),
            );
          }),
        );
      }),
    );
  });

  getCourseOfSkillsetInfo$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MicrocourseActions.getCourseOfSkillsetInfo),
      mergeMap((action) => {
        return this.MicrocourseService.getInfo(action.id).pipe(
          map((data) => {
            let microcourse = <Microcourse>objectToCamel(data);
            return MicrocourseActions.getCourseOfSkillsetInfoSuccess({
              microcourse: microcourse,
            });
          }),
          catchError((error) => {
            return of(
              MicrocourseActions.getCourseOfSkillsetInfoFailure({
                errorMessage: error,
              }),
            );
          }),
        );
      }),
    );
  });

  getEmbedContentById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MicrocourseActions.getEmbedContent),
      switchMap((action) => {
        return this.MicrocourseService.getEmbedContentById(action.id).pipe(
          map((data) => {
            return MicrocourseActions.getEmbedContentSuccess({
              embedContent: data,
            });
          }),
          catchError((error) => {
            return of(
              MicrocourseActions.getEmbedContentFailure({
                errorMessage: error,
              }),
            );
          }),
        );
      }),
    );
  });
}
