import { createAction, props } from '@ngrx/store';
import { Profile } from 'src/app/models/profile.model';

// get mine
export const getMine = createAction('[Profile] Get Mine Profile');

export const getMineSuccess = createAction(
  '[Profile] Get Mine Profile Success',
  props<{ mineProfile: Profile }>(),
);

export const getMineFailure = createAction(
  '[Profile] Get Mine Profile Failure',
  props<{ getMineErrorMessage: string }>(),
);

//create mine
export const createMine = createAction(
  '[Profile] Create Mine Profile',
  props<{ mineProfile: Profile }>(),
);

export const createMineSuccess = createAction(
  '[Profile] Create Mine Profile Success',
);

export const createMineFailure = createAction(
  '[Profile] Create Mine Profile Failure',
  props<{ createMineErrorMessage: string }>(),
);

//update mine
export const updateMine = createAction(
  '[Profile] Update Mine Profile',
  props<{ mineProfile: Profile }>(),
);

export const updateMineSuccess = createAction(
  '[Profile] Update Mine Profile Success',
);

export const updateMineFailure = createAction(
  '[Profile] Update Mine Profile Failure',
  props<{ updateMineErrorMessage: string }>(),
);

//getByPublic
export const getByPublic = createAction(
  '[Profile] Get By Public Profile',
  props<{ id: string }>(),
);

export const getByPublicSuccess = createAction(
  '[Profile] Get By Public Profile Success',
  props<{ userProfile: Profile }>(),
);

export const getByPublicFailure = createAction(
  '[Profile] Get By Public Profile Failure',
  props<{ getPublicErrorMessage: string }>(),
);

export const clearMineProfileStates = createAction(
  '[Profile] Clear Mine Profile States',
);

export const clearUserProfileStates = createAction(
  '[Profile] Clear User Profile States',
);

export const clearMineProfileMessage = createAction(
  '[Profile] Clear Mine Profile Message',
);

export const clearUserProfileMessage = createAction(
  '[Profile] Clear User Profile Message',
);
