import { Injectable } from '@angular/core';
import { HttpClientWithAuth } from '../../utils/HttpClientWithAuth';

@Injectable({
  providedIn: 'root',
})
export class PipesService {
  constructor(private http: HttpClientWithAuth) {}

  transform(contentJson: any) {
    return this.http.post(
      'https://editorpipe.svc.akademy.dev/transform?dark=false',
      contentJson,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        responseType: 'text',
      },
    );
  }
}
