import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';

import { StorageService } from 'src/app/services/storage/storage.service';
import * as StorageActions from './storage.actions';
import { UploadedFile } from 'src/app/models/storage.model';
import { camelToSnake } from 'src/app/utils/objectTransform';

@Injectable()
export class StorageEffects {
  constructor(
    private actions$: Actions,
    private storageService: StorageService,
  ) {}

  uploadUserAvatar$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(StorageActions.uploadUserAvatar),
      switchMap((action) => {
        return this.storageService
          .uploadFile(action.file, camelToSnake(action.fileName))
          .pipe(
            map((file) => {
              return StorageActions.uploadUserAvatarSuccess({
                uploadedUserAvatar: <UploadedFile>file,
              });
            }),
            catchError((error) => {
              return of(
                StorageActions.uploadUserAvatarFailure({ errorMessage: error }),
              );
            }),
          );
      }),
    );
  });
}
