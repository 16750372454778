import { createReducer, on } from '@ngrx/store';
import { StorageState } from './storage.state';
import * as StorageActions from './storage.actions';
import { UploadedFile } from 'src/app/models/storage.model';

const initialState: StorageState = {
  uploadedUserAvatar: <UploadedFile>{},
  isUploadingUserAvatar: false,
  uploadUserAvatarErrorMessage: '',
};

export const storageReducer = createReducer(
  initialState,

  on(StorageActions.uploadUserAvatar, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isUploadingUserAvatar: true,
      uploadUserAvatarErrorMessage: '',
    };
  }),
  on(StorageActions.uploadUserAvatarSuccess, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      uploadedUserAvatar: action.uploadedUserAvatar,
      isUploadingUserAvatar: false,
    };
  }),
  on(StorageActions.uploadUserAvatarFailure, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      isUploadingUserAvatar: false,
      uploadUserAvatarErrorMessage: action.errorMessage,
    };
  }),

  on(StorageActions.clearUserAvatarStates, (state, action) => {
    console.log(action.type);
    return {
      ...state,
      uploadedUserAvatar: <UploadedFile>{},
      isUploadingUserAvatar: false,
      uploadUserAvatarErrorMessage: '',
    };
  }),
);
