import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Microcourse } from 'src/app/models/microcourse.model';
import { HttpClientWithAuth } from '../../utils/HttpClientWithAuth';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MicrocourseService {
  constructor(private http: HttpClientWithAuth) {}

  getResponse(page: number, size: number) {
    return this.http.get(`/microcourse/list?page=${page}&size=${size}`);
  }

  getById(id: string) {
    return this.http.get(`/microcourse?id=${id}`);
  }

  search(query: string): Observable<readonly Microcourse[] | null> {
    return this.http.get(`/microcourse/search?query=${query}`);
  }

  getInfo(id: string) {
    return this.http.get(`/microcourse/info?id=${id}`);
  }

  getEmbedContentById(id: string) {
    return this.http.get(
      `/microcourse/embed?id=${id}&theme=default&platform=ios`,
      {
        responseType: 'text',
      },
    );
  }
}
