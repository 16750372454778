import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { catchError, of, map, switchMap, repeat } from 'rxjs';

import { AuthService } from 'src/app/services/auth/auth.service';
import * as AuthActions from './auth.actions';
import { User } from 'src/app/models/user.model';
import { objectToCamel } from 'src/app/utils/objectTransform';

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
  ) {}

  signInWithGoogle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.signInWithGoogle),
      switchMap(() => {
        return this.authService.signInWithGoogle();
      }),
      map(() => {
        return AuthActions.signInWithGoogleSuccess();
      }),
      catchError((error) => {
        return of(AuthActions.signInWithGoogleFailure({ errorMessage: error }));
      }),
      repeat(),
    );
  });

  signInWithSystemGG$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.signInWithSystemGG),
      switchMap(() => {
        return this.authService.signInWithSystemGG().pipe(
          map((data) => {
            return AuthActions.signInWithSystemGGSuccess({
              userCredential: <User>objectToCamel(data),
            });
          }),
          catchError((error) => {
            return of(
              AuthActions.signInWithSystemGGFailure({ errorMessage: error }),
            );
          }),
        );
      }),
    );
  });

  signUpWithSystemGG$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.signUpWithSystemGG),
      switchMap(() => {
        return this.authService.signUpWithSystemGG().pipe(
          map(() => {
            return AuthActions.signUpWithSystemGGSuccess();
          }),
          catchError((error) => {
            return of(
              AuthActions.signUpWithSystemGGFailure({ errorMessage: error }),
            );
          }),
        );
      }),
    );
  });
}
