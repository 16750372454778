import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { objectToCamel } from 'src/app/utils/objectTransform';

import { VoucherService } from 'src/app/services/voucher/voucher.service';
import * as VoucherActions from './voucher.actions';
import { Voucher } from 'src/app/models/voucher.model';

@Injectable()
export class VoucherEffects {
  constructor(
    private actions$: Actions,
    private voucherService: VoucherService,
  ) {}

  check$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VoucherActions.check),
      switchMap((action) => {
        return this.voucherService.check(action.voucher).pipe(
          map((data) => {
            return VoucherActions.checkSuccess({
              voucher: <Voucher>objectToCamel(data),
            });
          }),
          catchError((error) => {
            return of(VoucherActions.checkFailure({ errorMessage: error }));
          }),
        );
      }),
    );
  });

  redeem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VoucherActions.redeem),
      switchMap((action) => {
        return this.voucherService.redeem(action.voucher).pipe(
          map(() => {
            return VoucherActions.redeemSuccess();
          }),
          catchError((error) => {
            return of(VoucherActions.redeemFailure({ errorMessage: error }));
          }),
        );
      }),
    );
  });
}
