export const environment = {
  firebase: {
    apiKey: 'AIzaSyBNj54GgQdPoNb271PKrFSquyLYbz8uKZ0',
    authDomain: 'akademy-staging.firebaseapp.com',
    projectId: 'akademy-staging',
    storageBucket: 'akademy-staging.appspot.com',
    messagingSenderId: '1091698969857',
    appId: '1:1091698969857:web:86e87a0ebfc06ef91f1f00',
    measurementId: 'G-SXLGBK5SSD',
  },

  production: false,

  baseUrl: 'https://gateway.staging.akademy.dev',
  storageApi: 'https://storageapi.staging.akademy.dev',
  baseVersion: 'v1',
  nextVersion: 'v2',
  domain: {
    "ca": "learn.mikademy.ca",
    "vn": "learn.akademy.dev",
  },
  analytics: {
    "ca": "",
    "vn": ""
  }
};
