import { createAction, props } from '@ngrx/store';
import { UploadedFile } from 'src/app/models/storage.model';

export const uploadUserAvatar = createAction(
  '[Storage] Upload User Avatar',
  props<{ file: File; fileName: string }>(),
);
export const uploadUserAvatarSuccess = createAction(
  '[Storage] Upload User Avatar Success',
  props<{ uploadedUserAvatar: UploadedFile }>(),
);
export const uploadUserAvatarFailure = createAction(
  '[Storage] Upload User Avatar Failure',
  props<{ errorMessage: string }>(),
);

export const clearUserAvatarStates = createAction(
  '[Storage] Clear User Avatar States',
);
