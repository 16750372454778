import { createAction, props } from '@ngrx/store';
import {
  AnswerSheet,
  QuizAttempt,
  QuizManifest,
} from 'src/app/models/quiz.model';
import { QuizQuestion } from 'src/app/models/question.model';

export const getByCourseId = createAction(
  '[Quiz] Get Quiz Manifest By Course Id',
  props<{ courseId: string }>(),
);

export const getByCourseIdSuccess = createAction(
  '[Quiz] Get Quiz Manifest By Course Id Success',
  props<{ manifest: QuizManifest }>(),
);

export const getByCourseIdFailure = createAction(
  '[Quiz] Get Quiz Manifest By Course Id Failure',
  props<{ errorMessage: string }>(),
);

export const getQuestionById = createAction(
  '[Quiz] Get Quiz Question By Id',
  props<{ id: string }>(),
);

export const getQuestionByIdSuccess = createAction(
  '[Quiz] Get Quiz Question By Id Success',
  props<{ question: QuizQuestion }>(),
);

export const getQuestionByIdFailure = createAction(
  '[Quiz] Get Quiz Question By Id Failure',
  props<{ errorMessage: string }>(),
);

export const getQuestionsByCourseId = createAction(
  '[Quiz] Get Quiz Question By Course Id',
  props<{ courseId: string }>(),
);

export const getQuestionsByCourseIdSuccess = createAction(
  '[Quiz] Get Quiz Question By Course Id Success',
  props<{ questionList: QuizQuestion[] }>(),
);

export const getQuestionsByCourseIdFailure = createAction(
  '[Quiz] Get Quiz Question By Course Id Failure',
  props<{ errorMessage: string }>(),
);

export const createAttempt = createAction(
  '[Quiz] Create Quiz Attempt',
  props<{ attempt: string; quizId: string }>(),
);

export const createAttemptSuccess = createAction(
  '[Quiz] Create Quiz Attempt Success',
  props<{ attempt: QuizAttempt }>(),
);

export const createAttemptFailure = createAction(
  '[Quiz] Create Quiz Attempt Failure',
  props<{ errorMessage: string }>(),
);

export const submitAttempt = createAction(
  '[Quiz] Submit Quiz Attempt',
  props<{ answerSheet: AnswerSheet; attemptId: string }>(),
);

export const submitAttemptSuccess = createAction(
  '[Quiz] Submit Quiz Attempt Success',
);

export const submitAttemptFailure = createAction(
  '[Quiz] Submit Quiz Attempt Failure',
  props<{ errorMessage: string }>(),
);

export const getMineAttempts = createAction(
  '[Quiz] Get Mine Quiz Attempts',
  props<{ quizId: string }>(),
);

export const getMineAttemptsSuccess = createAction(
  '[Quiz] Get Mine Quiz Attempts Success',
  props<{ attemptList: QuizAttempt[] }>(),
);

export const getMineAttemptsFailure = createAction(
  '[Quiz] Get Mine Quiz Attempts Failure',
  props<{ errorMessage: string }>(),
);

export const getAttemptById = createAction(
  '[Quiz] Get Quiz Attempt By Id',
  props<{ id: string }>(),
);

export const getAttemptByIdSuccess = createAction(
  '[Quiz] Get Quiz Attempt By Id Success',
  props<{ attempt: QuizAttempt }>(),
);

export const getAttemptByIdFailure = createAction(
  '[Quiz] Get Quiz Attempt By Id Failure',
  props<{ errorMessage: string }>(),
);

export const resetStates = createAction('[Quiz] Reset States');

export const clearQuestionMessage = createAction(
  '[Quiz] Clear Question Message',
);
export const clearQuizMessage = createAction('[Quiz] Clear Quiz Message');
export const clearQuizAttemptMessage = createAction(
  '[Quiz] Clear Quiz Attempt Message',
);
export const confirmSubmit = createAction(
  '[Quiz] Confirm Submit Attempt',
  props<{ confirm: number }>(),
);

export const getQuestionInAttemptById = createAction(
  '[Quiz] Get Quiz Question In Attempt By Id',
  props<{ id: string }>(),
);

export const getQuestionInAttemptByIdSuccess = createAction(
  '[Quiz] Get Quiz Question In Attempt By Id Success',
  props<{ question: QuizQuestion }>(),
);

export const getQuestionInAttemptByIdFailure = createAction(
  '[Quiz] Get Quiz Question In Attempt By Id Failure',
  props<{ errorMessage: string }>(),
);

export const getAttemptBestResult = createAction(
  '[Quiz] Get Quiz Attempt Best Result',
  props<{ quizId: string }>(),
);

export const getAttemptBestResultSuccess = createAction(
  '[Quiz] Get Quiz Attempt Best Result Success',
  props<{ attempt: QuizAttempt }>(),
);

export const getAttemptBestResultFailure = createAction(
  '[Quiz] Get Quiz Attempt Best Result Failure',
  props<{ errorMessage: string }>(),
);

export const countAttemptsByUserId = createAction(
  '[Quiz] Count Quiz Attempts By User Id',
);
export const countAttemptsByUserIdSuccess = createAction(
  '[Quiz] Count Quiz Attempts By User Id Success',
  props<{ count: number }>(),
);
export const countAttemptsByUserIdFailure = createAction(
  '[Quiz] Count Quiz Attempts By User Id Failure',
  props<{ errorMessage: string }>(),
);

export const getEmbedContent = createAction(
  '[Quiz] Get Embed Content',
  props<{ contentJson: any }>(),
);
export const getEmbedContentSuccess = createAction(
  '[Quiz] Get Embed Content Success',
  props<{ embedContentByContentJson: string }>(),
);
export const getEmbedContentErrorMessage = createAction(
  '[Quiz] Get Embed Content Error Message',
  props<{ errorMessage: string }>(),
);
