import { Voucher } from 'src/app/models/voucher.model';
import { VoucherState } from './voucher.state';
import { createReducer, on } from '@ngrx/store';

import * as VoucherActions from './voucher.actions';

const initialState: VoucherState = {
  voucher: <Voucher>{},
  isChecking: false,
  checkErrorMessage: '',

  isRedeeming: false,
  isRedeemSuccess: false,
  redeemErrorMessage: '',
};

export const voucherReducer = createReducer(
  initialState,
  on(VoucherActions.check, (state, { type }) => {
    console.log(type);
    return <VoucherState>{
      ...state,
      isChecking: true,
      voucher: <Voucher>{},
      checkErrorMessage: '',
    };
  }),
  on(VoucherActions.checkSuccess, (state, { type, voucher }) => {
    console.log(type);
    return <VoucherState>{
      ...state,
      isChecking: false,
      voucher: voucher,
    };
  }),
  on(VoucherActions.checkFailure, (state, { type, errorMessage }) => {
    console.log(type);
    return <VoucherState>{
      ...state,
      isChecking: false,
      checkErrorMessage: errorMessage,
    };
  }),

  on(VoucherActions.redeem, (state, { type }) => {
    console.log(type);
    return <VoucherState>{
      ...state,
      isRedeeming: true,
      isRedeemSuccess: false,
      redeemErrorMessage: '',
    };
  }),
  on(VoucherActions.redeemSuccess, (state, { type }) => {
    console.log(type);
    return <VoucherState>{
      ...state,
      isRedeeming: false,
      isRedeemSuccess: true,
    };
  }),
  on(VoucherActions.redeemFailure, (state, { type, errorMessage }) => {
    console.log(type);
    return <VoucherState>{
      ...state,
      isRedeeming: false,
      redeemErrorMessage: errorMessage,
    };
  }),

  on(VoucherActions.clearStates, (state) => {
    return <VoucherState>{
      ...state,
      voucher: <Voucher>{},
      isChecking: false,
      checkErrorMessage: '',

      isRedeeming: false,
      isRedeemSuccess: false,
      redeemErrorMessage: '',
    };
  }),
);
