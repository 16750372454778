import { Injectable } from '@angular/core';
import { MicrocourseGroup } from 'src/app/models/skillset.model';
import { HttpClientWithAuth } from 'src/app/utils/HttpClientWithAuth';

@Injectable({
  providedIn: 'root',
})
export class SkillsetService {
  constructor(private http: HttpClientWithAuth) {}

  //microcourse_group
  getSkillsetById(id: string) {
    return this.http.get(`/skillset/microcourse_group?id=${id}`);
  }

  listInternalSkillset(page: number, size: number) {
    return this.http.get(
      `/skillset/microcourse_group/list/internal?page=${page}&size=${size}`,
    );
  }

  listPublishedSkillset(page: number, size: number) {
    return this.http.get(
      `/skillset/microcourse_group/list?page=${page}&size=${size}`,
    );
  }

  getSkillsetPublishStatus(id: string) {
    return this.http.get(`/skillset/microcourse_group/publish?id=${id}`);
  }

  searchSkillset(query: string) {
    return this.http.get(`/skillset/microcourse_group/search?query=${query}`);
  }

  //certificate
  getCertsByUserId(userId: string) {
    return this.http.get(`/skillset/certificate/user?user_id=${userId}`);
  }

  getCertBySkillsetIdAndUserId(skillsetId: string, userId: string) {
    return this.http.get(
      `/skillset/certificate/check?user_id=${userId}&skillset_id=${skillsetId}`,
    );
  }

  countCertBySkillsetId(skillsetId: string) {
    return this.http.get(
      `/skillset/certificate/count-by-skillset?skillset_id=${skillsetId}`,
    );
  }

  requestCertForIssuing(skillsetId: string) {
    return this.http.post(
      `/skillset/certificate/issue?skillset_id=${skillsetId}`,
      {},
    );
  }
}
