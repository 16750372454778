import { Injectable } from '@angular/core';
import { Profile } from 'src/app/models/profile.model';
import { HttpClientWithAuth } from '../../utils/HttpClientWithAuth';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private http: HttpClientWithAuth) {}

  getMine() {
    return this.http.get('/profile/mine');
  }

  createMine(profile: Profile) {
    return this.http.post('/profile/mine', profile);
  }

  updateMine(profile: Profile) {
    return this.http.put('/profile/mine', profile);
  }

  getByPublic(id: string) {
    return this.http.get(`/profile/public?id=${id}`);
  }
}
