import { Profile } from 'src/app/models/profile.model';
import { ProfileState } from './profile.state';
import { createReducer, on } from '@ngrx/store';
import * as ProfileActions from './profile.actions';

const initialState: ProfileState = {
  // mine
  mineProfile: <Profile>{},

  isGettingMine: false,
  getMineErrorMessage: '',

  isCreateMine: false,
  isCreateMineSuccess: false,
  createMineErrorMessage: '',

  isUpdateMine: false,
  isUpdateMineSuccess: false,
  updateMineErrorMessage: '',

  // user profile
  userProfile: <Profile>{},

  isGettingPublic: false,
  isGetPublicSuccess: false,
  getPublicErrorMessage: '',
};

export const profileReducer = createReducer(
  initialState,
  on(ProfileActions.getMine, (state, action) => {
    console.log(action.type);
    return <ProfileState>{
      ...state,
      mineProfile: <Profile>{},
      isGettingMine: true,
      getMineErrorMessage: '',
    };
  }),
  on(ProfileActions.getMineSuccess, (state, { type, mineProfile }) => {
    console.log(type);
    return <ProfileState>{
      ...state,
      mineProfile: mineProfile,
      isGettingMine: false,
    };
  }),
  on(ProfileActions.getMineFailure, (state, { type, getMineErrorMessage }) => {
    console.log(type);
    return <ProfileState>{
      ...state,
      isGettingMine: false,
      getMineErrorMessage: getMineErrorMessage,
    };
  }),

  on(ProfileActions.createMine, (state, action) => {
    console.log(action.type);
    return <ProfileState>{
      ...state,
      isCreateMine: true,
      isCreateMineSuccess: false,
    };
  }),
  on(ProfileActions.createMineSuccess, (state, action) => {
    console.log(action.type);
    return <ProfileState>{
      ...state,
      isCreateMine: false,
      isCreateMineSuccess: true,
    };
  }),
  on(
    ProfileActions.createMineFailure,
    (state, { type, createMineErrorMessage }) => {
      console.log(type);
      return <ProfileState>{
        ...state,
        isCreateMine: false,
        isCreateMineSuccess: false,
        createMineErrorMessage: createMineErrorMessage,
      };
    },
  ),

  on(ProfileActions.updateMine, (state, action) => {
    console.log(action.type);
    return <ProfileState>{
      ...state,
      isUpdateMine: true,
      isUpdateMineSuccess: false,
    };
  }),
  on(ProfileActions.updateMineSuccess, (state, action) => {
    console.log(action.type);
    return <ProfileState>{
      ...state,
      isUpdateMine: false,
      isUpdateMineSuccess: true,
    };
  }),
  on(
    ProfileActions.updateMineFailure,
    (state, { type, updateMineErrorMessage }) => {
      console.log(type);
      return <ProfileState>{
        ...state,
        isUpdateMine: false,
        isUpdateMineSuccess: false,
        updateMineErrorMessage: updateMineErrorMessage,
      };
    },
  ),

  on(ProfileActions.getByPublic, (state, { type }) => {
    console.log(type);
    return <ProfileState>{
      ...state,
      isGettingPublic: true,
      isGetPublicSuccess: false,
    };
  }),
  on(ProfileActions.getByPublicSuccess, (state, { type, userProfile }) => {
    console.log(type);
    return <ProfileState>{
      ...state,
      userProfile: userProfile,
      isGettingPublic: false,
      isGetPublicSuccess: true,
    };
  }),
  on(
    ProfileActions.getByPublicFailure,
    (state, { type, getPublicErrorMessage }) => {
      console.log(type);
      return <ProfileState>{
        ...state,
        isGettingPublic: false,
        isGetPublicSuccess: false,
        getPublicErrorMessage: getPublicErrorMessage,
      };
    },
  ),

  on(ProfileActions.clearMineProfileStates, (state, action) => {
    console.log(action.type);
    return <ProfileState>{
      ...state,
      mineProfile: <Profile>{},

      isGettingMine: false,
      getMineErrorMessage: '',

      isCreateMine: false,
      isCreateMineSuccess: false,
      createMineErrorMessage: '',

      isUpdateMine: false,
      isUpdateMineSuccess: false,
      updateMineErrorMessage: '',
    };
  }),
  on(ProfileActions.clearUserProfileStates, (state, action) => {
    console.log(action.type);
    return <ProfileState>{
      ...state,
      userProfile: <Profile>{},

      isGettingPublic: false,
      isGetPublicSuccess: false,
      getPublicErrorMessage: '',
    };
  }),

  on(ProfileActions.clearMineProfileMessage, (state, action) => {
    console.log(action.type);
    return <ProfileState>{
      ...state,
      isGettingMine: false,
      getMineErrorMessage: '',

      isCreateMine: false,
      isCreateMineSuccess: false,
      createMineErrorMessage: '',

      isUpdateMine: false,
      isUpdateMineSuccess: false,
      updateMineErrorMessage: '',
    };
  }),
  on(ProfileActions.clearUserProfileMessage, (state, action) => {
    console.log(action.type);
    return <ProfileState>{
      ...state,
      isGetting: false,
      isGetSuccess: false,
      getErrorMessage: '',

      isGettingPublic: false,
      isGetPublicSuccess: false,
      getPublicErrorMessage: '',
    };
  }),
);
