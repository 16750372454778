import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AssignmentService } from 'src/app/services/assignment/assignment.service';
import { catchError, map, of, switchMap } from 'rxjs';
import { camelToSnake, objectToCamel } from 'src/app/utils/objectTransform';

import * as AssignmentActions from './assignment.actions';
import { Assignment } from 'src/app/models/assignment.model';

@Injectable()
export class AssignmentEffects {
  constructor(
    private actions$: Actions,
    private assignmentService: AssignmentService,
  ) {}

  create$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AssignmentActions.create),
      switchMap((action) => {
        let assignment = <Assignment>{
          ...action.assignment,
          content: JSON.stringify(action.assignment.contentJson),
        };
        return this.assignmentService.create(camelToSnake(assignment)).pipe(
          map(() => {
            return AssignmentActions.createSuccess();
          }),
          catchError((error) => {
            return of(AssignmentActions.createFailure({ errorMessage: error }));
          }),
        );
      }),
    );
  });

  getById$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AssignmentActions.getById),
      switchMap((action) => {
        return this.assignmentService.getById(action.id).pipe(
          map((data) => {
            let assignment = <Assignment>objectToCamel(data);
            assignment.contentJson = JSON.parse(assignment.content);
            assignment.creating = false;

            return AssignmentActions.getByIdSuccess({
              assignment: assignment,
            });
          }),
          catchError((error) => {
            return of(
              AssignmentActions.getByIdFailure({ errorMessage: error }),
            );
          }),
        );
      }),
    );
  });

  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AssignmentActions.update),
      switchMap((action) => {
        let assignment = <Assignment>{
          ...action.assignment,
          content: JSON.stringify(action.assignment.contentJson),
        };
        return this.assignmentService.update(camelToSnake(assignment)).pipe(
          map(() => {
            return AssignmentActions.updateSuccess();
          }),
          catchError((error) => {
            return of(AssignmentActions.updateFailure({ errorMessage: error }));
          }),
        );
      }),
    );
  });
}
